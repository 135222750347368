import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError, tap } from 'rxjs/operators';
import { WorkCardAccessorService } from '../../widgets/staar/service-modules/data-accessors/work-card-accessor.service';
import { popToastError } from '../toast/toast';
import {
  fetchAttachments,
  fetchTechData,
  fetchWorkCardFiles,
  setAttachments,
  setTechData,
  setWorkCardFile,
  fetchParts,
  setParts,
  fetchTools,
  setTools,
  fetchWorkCard,
  addWorkCards,
  fetchSceptreDetails,
  setSceptreRequired,
  fetchLogPages,
  fetchAuditClosureReviews,
  setLogPages,
  setAuditQueue,
  noop,
  setAuditClosureReviews,
  setCustomerWorkCardQueue,
  fetchCIRSteps,
  setCIRSteps,
  fetchCircuitBreakers,
  setCircuitBreakers,
} from './workcards.actions';

export const AUDIT_QUEUE_SESSION_STORAGE_KEY = 'auditQueueWorkCardIds';
export const CUSTOMER_QUEUE_SESSION_STORAGE_KEY = 'customerQueueWorkCardIds';

@Injectable({
  providedIn: 'root',
})
export class WorkCardEffects {
  fetchWorkCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchWorkCard.type),
      exhaustMap((action: any) =>
        this.workCardAccessor.getWorkCardDetails(action.workCardId).pipe(
          map((workCardDetails) =>
            addWorkCards({
              workCards: [workCardDetails],
            })
          ),
          catchError((err) =>
            of(popToastError({ toast: { message: err.message } }))
          )
        )
      )
    )
  );

  fetchWorkCardFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchWorkCardFiles.type),
      exhaustMap((action: any) =>
        this.workCardAccessor.getWorkCardPdf(action.workCardDetails).pipe(
          map((file) =>
            setWorkCardFile({
              workCardId: action.workCardDetails.WORK_CARD_ID,
              file,
            })
          ),
          catchError((err) =>
            of(popToastError({ toast: { message: err.message } }))
          )
        )
      )
    )
  );

  fetchAttachments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAttachments.type),
      exhaustMap((action: any) =>
        this.workCardAccessor.getAttachments(action.workCardDetails).pipe(
          map((attachments) =>
            setAttachments({
              workCardId: action.workCardDetails.WORK_CARD_ID,
              attachments,
            })
          ),
          catchError((err) =>
            of(popToastError({ toast: { message: err.message } }))
          )
        )
      )
    )
  );

  fetchCIRSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCIRSteps.type),
      exhaustMap((action: any) =>
        this.workCardAccessor.getCIRSteps(action.workCardDetails).pipe(
          map((steps) =>
            setCIRSteps({
              workCardId: action.workCardDetails.WORK_CARD_ID,
              steps,
            })
          ),
          catchError((err) =>
            of(popToastError({ toast: { message: err.message } }))
          )
        )
      )
    )
  );

  fetchTechData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchTechData.type),
      exhaustMap((action: any) =>
        this.workCardAccessor.getTechDataFiles(action.workCardDetails).pipe(
          map((techData) =>
            setTechData({
              workCardId: action.workCardDetails.WORK_CARD_ID,
              techData,
            })
          ),
          catchError((err) =>
            of(popToastError({ toast: { message: err.message } }))
          )
        )
      )
    )
  );

  fetchParts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchParts.type),
      exhaustMap((action: any) =>
        this.workCardAccessor.fetchRequiredParts(action.workCardDetails).pipe(
          map((parts) =>
            setParts({
              workCardId: action.workCardDetails.WORK_CARD_ID,
              parts,
            })
          ),
          catchError((err) =>
            of(popToastError({ toast: { message: err.message } }))
          )
        )
      )
    )
  );

  fetchTools$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchTools.type),
      exhaustMap((action: any) =>
        this.workCardAccessor.fetchTools(action.workCardDetails).pipe(
          map((tools) =>
            setTools({
              workCardId: action.workCardDetails.WORK_CARD_ID,
              tools,
            })
          ),
          catchError((err) =>
            of(popToastError({ toast: { message: err.message } }))
          )
        )
      )
    )
  );

  fetchSceptreDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSceptreDetails.type),
      exhaustMap((action: any) =>
        this.workCardAccessor
          .fetchCardClosureSceptreDetails(action.workCardDetails)
          .pipe(
            map((sceptreDetails) =>
              setSceptreRequired({
                workCardId: action.workCardDetails.WORK_CARD_ID,
                sceptreRequired: sceptreDetails.required,
              })
            ),
            catchError((err) =>
              of(popToastError({ toast: { message: err.message } }))
            )
          )
      )
    )
  );

  fetchLogPages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchLogPages.type),
      exhaustMap((action: any) =>
        this.workCardAccessor.fetchLogPages(action.workCardDetails).pipe(
          map((logPages) =>
            setLogPages({
              workCardId: action.workCardDetails.WORK_CARD_ID,
              logPages,
            })
          ),
          catchError((err) =>
            of(popToastError({ toast: { message: err.message } }))
          )
        )
      )
    )
  );

  fetchAuditClosureReviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAuditClosureReviews.type),
      exhaustMap((action: any) =>
        this.workCardAccessor
          .fetchAllAuditClosureReview(action.workCardDetails)
          .pipe(
            map((auditReviews) =>
              setAuditClosureReviews({
                workCardId: action.workCardDetails.WORK_CARD_ID,
                auditReviews,
              })
            ),
            catchError((err) =>
              of(popToastError({ toast: { message: err.message } }))
            )
          )
      )
    )
  );

  persistAuditQueue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setAuditQueue.type),
      tap((action: any) => {
        sessionStorage.setItem(
          AUDIT_QUEUE_SESSION_STORAGE_KEY,
          JSON.stringify(action.auditQueue)
        );
      }),
      exhaustMap(() => of(noop()))
    )
  );

  persistCustomerQueue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCustomerWorkCardQueue.type),
      tap((action: any) => {
        sessionStorage.setItem(
          CUSTOMER_QUEUE_SESSION_STORAGE_KEY,
          JSON.stringify(action.queue)
        );
      }),
      exhaustMap(() => of(noop()))
    )
  );

  fetchCircuitBreakers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCircuitBreakers.type),
      exhaustMap((action: any) =>
        this.workCardAccessor.fetchCircuitBreakers(action.workCardDetails).pipe(
          map((circuitBreakers) =>
            setCircuitBreakers({
              workCardId: action.workCardDetails.WORK_CARD_ID,
              circuitBreakers,
            })
          ),
          catchError((err) =>
            of(popToastError({ toast: { message: err.message } }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private workCardAccessor: WorkCardAccessorService
  ) {}
}
