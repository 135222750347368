export class ConcourseUser {
  public parsedRoles: string[];

  constructor(
    public id: number,
    public userName: string,
    public email: string,
    public firstName: string,
    public lastName: string,
    public employeeID: string | number,
    public roleName: string,
    public division: string,
    public phoneNumber: string | number,
    public userTypeId: string | number,
    public createdBy: string,
    public lastLoggedInDate: string | Date,
    public roles: string,
    public widgets: string,
    public token: any,
  ) {
    // Parses each role from the roleName string, trimming whitespace before/after
    this.parsedRoles = this.roleName.split(',')
      .map(role => role.trim());
  }

  static get localDevelopmentUser(): ConcourseUser {
    return new ConcourseUser(
      10,
      "AAR.Digital.Services@aarcorp.com",
      "AAR.Digital.Services@aarcorp.com",
      "AAR",
      "Local-Development",
      "33714",
      "Developer",
      "ASM",
      null,
      0,
      "AAR.Digital.Services@aarcorp.com",
      new Date("2024-01-01"),
      null,
      null,
      null,
    );
  }
}
