export class PathConstants {
  public static readonly subPath = 'concourse/staar/project/v1/';
  public static readonly subPathV2 = 'concourse/staar/project/v2/';
  public static readonly subFilePath = 'concourse/staar/pdftron/v1/';
  public static readonly subFilePathV2 = 'concourse/staar/pdftron/v2/';
  public static readonly subPath_WF = 'concourse/staar/workforce/v1/';
  public static readonly tokenSubPath = 'concourse/staar/authentication/v1/';
  public static readonly subPatheA = 'concourse/staar/workforce/v1/';
  public static readonly subPathClock = 'concourse/staar/clockservice/v1/';
  public static readonly subPathImage = 'concourse/image/';
  public static readonly subPathMroV1 = 'imops/mro/v1/';
  public static readonly mroPath = '/imops/mro/v1/';
  public static readonly subPathPubSub = 'pubsub/';
  public static readonly checkPINAvailability = PathConstants.subPath_WF+ 'checkPinAvailability';
  public static readonly auditPIN = PathConstants.subPath_WF+ 'auditPinDetails';
  public static readonly updatePIN = PathConstants.subPath_WF+ 'changeEmployeePin';
  public static readonly getToken = PathConstants.tokenSubPath + 'createToken';
  public static readonly projectList = PathConstants.subPathV2 + 'projectList';
  public static readonly projectWorkCardList = PathConstants.subPathV2 + 'projectWorkCardList';
  public static readonly workCardTimeClock = PathConstants.subPath + 'timeClockInfo';
  public static readonly sendTOREmail = PathConstants.subPath + 'sendTurnOverReportEmail';
  public static readonly employeeProfile = PathConstants.subPath_WF + 'employeeProfile';
  public static readonly customerProfile = PathConstants.subPath_WF + 'customerRepDetails';
  public static readonly employeeWorkCardHistory = PathConstants.subPath_WF + 'employeeWorkCardHistory';
  public static readonly facilityEmployees = PathConstants.subPath_WF + 'facilityEmployees';
  public static readonly workCardFilterCounts = PathConstants.subPath + 'workCardFilterCounts';
  public static readonly createWorkCardActivityPin = PathConstants.subPath + 'createWorkCardActivity';
  public static readonly createWorkCardActivity = PathConstants.subPath + 'createWorkCardActivity';
  public static readonly createWorkCardActivityAssign = PathConstants.subPath + 'createWorkCardActivity';
  public static readonly employeeDailySchedule = PathConstants.subPath_WF + 'employeeDailySchedule';
  public static readonly projectFiles = PathConstants.subPath + 'projectFiles';
  public static readonly employeeCurrentAssignments = PathConstants.subPath_WF + 'employeeCurrentAssignments';
  public static readonly employeeAuthorizations = PathConstants.subPatheA + 'employeeAuthorizations';
  public static readonly publishAssignments = PathConstants.subPath + 'createWorkCardActivity';
  public static readonly uploadFiles = PathConstants.subFilePath + 'filesActivity';
  public static readonly workcardFiles = PathConstants.subPath + 'workcardFiles';
  public static readonly buildSplittingModel = PathConstants.subFilePath + 'buildSplittingModel';
  public static readonly validateWorkCardSplitModel = PathConstants.subFilePath + 'validateCustomerCard';
  public static readonly validateMetaDataAndUpdate = PathConstants.subFilePath + 'validateMetaDataAndUpdate';
  public static readonly createTaskCards = PathConstants.subFilePath + 'executeSplittingModel';
  public static readonly employeeDivisions = PathConstants.subPath_WF + 'employeeDivisions';
  public static readonly workCardPartsList = PathConstants.mroPath + 'workCardParts';
  public static readonly employeePinValidation = PathConstants.subPath_WF + 'employeePinValidation';
  public static readonly workcardTools = PathConstants.subPath + 'workcardTools';
  public static readonly applyStamp = PathConstants.subPath + 'workStepActivity';
  public static readonly workStepAction = PathConstants.subPath + 'workStepAction';
  public static readonly validatingLaborClock = PathConstants.subPathClock + 'validatingLaborClock';
  public static readonly workCardSteps = PathConstants.subPath + 'cardSteps';
  public static readonly getEmployeeStamps = PathConstants.subPath_WF + 'getEmployeeStamps';
  public static readonly fetchAnnotationDataPath = PathConstants.subFilePath + 'getAnnotationMetaData';
  public static readonly uploadAnnotationPath = PathConstants.subFilePath + 'uploadAnnotationMetaData';
  public static readonly rejectInspectionOptions = PathConstants.subPath + 'rejectInspectionOptions';
  public static readonly fetchEmployeeStamps = PathConstants.subPath_WF + 'getEmployeeStamps';
  public static readonly customerStepsAction = PathConstants.subPath + "customerStepsAction";
  public static readonly modifyWorkCards = PathConstants.subPath + 'modifyWorkCards';
  public static readonly laborClockTracking = PathConstants.subPathClock + 'laborTracking';
  public static readonly workCardPDFAccess = PathConstants.subPath + 'workCardPDFAccess';
  public static readonly submitClosureReview = PathConstants.subPath + 'submitClosureReview';
  public static readonly auditClosureReview = PathConstants.subPath + 'auditClosureReview';
  public static readonly auditProcessActivity = PathConstants.subPath + 'auditProcessActivity';
  public static readonly auditProcessResubmitActivity = PathConstants.subPath + 'auditProcessResubmitActivity';
  public static readonly circuitBreakers = PathConstants.subPath + 'circuitBreakers';
  public static readonly getCirDesignatorStamp = PathConstants.subPathImage + 'createCirDesignatorStamp';
  public static readonly generateContinuationSheet = PathConstants.subFilePath + 'generateContinuationSheet';
  public static readonly projectWorkCardExpDetails = PathConstants.subPath + 'projectWorkCardExpDetails';
  public static readonly discrepancyLocation = PathConstants.subPath + 'divisionLocations';
  public static readonly discrepancySkill = PathConstants.subPath + 'divisionSkills';
  public static readonly discrepancyContractGroup = PathConstants.subPath + 'discrepancyContractGroup';
  public static readonly discrepancyStatusReason = PathConstants.subPath + 'discrepancyStatusReason';
  public static readonly discrepancyDotNumber = PathConstants.subPath + 'discrepancyDotNumber';
  public static readonly discrepancyHeaders = PathConstants.subPath + 'discrepancyHeaders';
  public static readonly createDiscrepancy = PathConstants.subPath + 'createDiscrepancy';
  public static readonly divisionAtaCodes = PathConstants.subPath + 'divisionAtaCodes';
  public static readonly partsList = PathConstants.subPathMroV1 + 'partsList';
  public static readonly updateDiscrepancy = PathConstants.subPath + 'updateDiscrepancy';
  public static readonly releaseDiscrepancy = PathConstants.subPath + 'releaseDiscrepancy';
  public static readonly workCardEventHistory = PathConstants.subPath + 'workCardEventHistory';
  public static readonly newPartRequisitionOrder = PathConstants.subPath + 'newPartRequisitionOrder';
  public static readonly partFolderStructure = PathConstants.mroPath + 'partFolderStructure';
  public static readonly generatePartsIssuedtags = PathConstants.mroPath + 'generatePartsIssuedtags';
  public static readonly myBoeingFleet = 'https://www.myboeingfleet.com/';
  public static readonly routineStepsGroupActivity = PathConstants.subPath + 'routineStepsGroupActivity';
  public static readonly routineWorkCardStepsAction = PathConstants.subPath + "routineWorkSteps";
  public static readonly manageDiscrepancy = PathConstants.subPath + 'manageDiscrepancy';
  public static readonly withdrawnCardDetails = PathConstants.subPath + 'withdrawnCardDetails';
  public static readonly withdrawCard = PathConstants.subPath + 'withdrawCard';
  public static readonly modifyCardStatus = PathConstants.subPath + 'modifyCardStatus';
  public static readonly routineFlagCheck = PathConstants.subPath + 'routineCheck';
  public static readonly routineStepGroupIdList = PathConstants.subPath + 'routineStepGroupIdList';
  public static readonly cardClosureSceptreDetails = PathConstants.subPath + 'cardClosureSceptreDetails';
  public static readonly addSceptreData = PathConstants.subPath + 'addSceptreData';
  public static readonly laborTimeAndCardCount = PathConstants.subPath + 'laborTimeAndCardCount';
  public static readonly evaluateNRC = PathConstants.subPath + 'evaluateNRC';
  public static readonly routineCustomerSteps = PathConstants.subPath + 'customerSteps';
  public static readonly activeWorkCardList = PathConstants.subPath + 'activeWorkcardList';
  public static readonly employeeMultiCardAuthCheck = PathConstants.subPath_WF + 'employeeMultiCardAuthCheck';
  public static readonly createCustomerStamp = PathConstants.subPathImage + 'createCustomerStamp';
  public static readonly leadAssignmentAuthCheck = PathConstants.subPath_WF + 'leadAssignmentAuthCheck';
  public static readonly confirmAuth = PathConstants.subPath_WF + 'confirmAuth';
  public static readonly updatePrimarySkills = PathConstants.subPath + 'updatePrimarySkills';
  public static readonly workCardSkills = PathConstants.subPath + 'workCardSkills';
  public static readonly statusReasonList = PathConstants.subPath + 'statusReasonList';
  public static readonly exportProject = PathConstants.subFilePathV2 + 'exportProject';
  public static readonly rotableAndRobbedPartsList = PathConstants.subPath + 'rotableAndRobbedPartsList';
  public static readonly createRotableRobbedStep = PathConstants.subPath + 'createRotableRobbedStep';
  public static readonly employeesAssignedOnWorkCard = PathConstants.subPath + 'employeesAssignedOnWorkCard';
  public static readonly listTechLibraryDocuments = PathConstants.subFilePath + 'listTechLibraryDocuments';
  public static readonly addFromTechLibrary = PathConstants.uploadFiles + '/addFromTechLibrary';
  public static readonly addFromSkippedWorkCards = PathConstants.subFilePath + 'filesActivity/addFromSkippedWorkCards';
  public static readonly laborDirectFlag = PathConstants.subPath_WF + 'laborDirectFlag';
  public static readonly prevWorkCardsAndStndByCard = PathConstants.subPath + 'prevWorkCardsAndStndByCard';
  public static readonly getEmployeeWorkCardNote = PathConstants.subPath + 'getEmployeeWorkCardNote';
  public static readonly employeeWorkCardNote = PathConstants.subPath + 'employeeWorkCardNote';
  public static readonly shiftCodes = PathConstants.subPath + 'getShiftCodes';
  public static readonly createTurnOverReport = PathConstants.subPath + 'createTurnOverReport';
  public static readonly readTurnOverReport = PathConstants.subPath + 'readTurnOverReport';
  public static readonly turnOverType = PathConstants.subPath + 'getTurnOverType';
  public static readonly exportItem = PathConstants.subFilePathV2 + 'exportItem';
  public static readonly webSocketUrl = PathConstants.subPathPubSub + 'webSocketUrl';
  public static readonly addUserToGroup = PathConstants.subPathPubSub + 'addUserToGroup';
  public static readonly removeUserFromGroup = PathConstants.subPathPubSub + 'removeUserFromGroup';
  public static readonly getCustomerStampByUserId = 'concourse/v1/GetCustomerStampByUserId';
  public static readonly getCustomerStamps = 'concourse/v1/GetCustomerStamps';
  public static readonly getVisualSignatureImage = 'concourse/v1/getVisualSignatureImage';
  public static readonly createTextStamp = PathConstants.subPathImage + 'createTextStamp';
  public static readonly updateTargetStartDay = PathConstants.subPath + 'updateTargetStartDay';
  public static readonly getLogPageNumber = PathConstants.subPath + 'getLogPageNumber';
  public static readonly createLogPageNumber = PathConstants.subPath + 'createLogPageNumber';
  public static readonly clockInEmployeeList = PathConstants.subPath + 'clockInEmployeeList';
  public static readonly workCardAssignmentCount = PathConstants.subPath + 'workCardAssignmentCount';
  public static readonly activeInspection = PathConstants.subPath + 'activeInspection';
  public static readonly cardClosureCheckList = PathConstants.subPath + 'cardClosureCheckList';
  public static readonly recordQueueCheckList = PathConstants.subPath + 'recordQueueCheckList';
  public static readonly workStepFinalAction = PathConstants.subPath + 'workStepFinalAction';
  public static readonly supervisorReviewAudit = PathConstants.subPath + 'supervisorReviewAudit';
  public static readonly customerSendWorkCard = PathConstants.subPath + 'customerSendWorkCardAudit';
  public static readonly supervisorSendCardToLead = PathConstants.subPath + 'supervisorSendCardToLead';
}
